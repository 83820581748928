
const DEFAULT_LOGO =
  "https://gravatar.com/avatar/446b9c716e6561d9318bc34f55870323"
export default {
  name: "LogoDisplayer",
  props: {
    domain: {
      type: String,
      default: null,
    },
    // Indicate either the company or user for the default icon
    defaultLogo: {
      type: String,
      default: "company",
    },
    size: {
      type: Number,
      default: 30,
    },
  },
  data() {
    return {
      src: DEFAULT_LOGO,
      isErrorIcon: false,
    }
  },
  computed: {
    isEvercamDomain() {
      return (
        this.domain?.includes("evercam.io") ||
        this.domain?.includes("evercam.com")
      )
    },
    small() {
      return this.size < 30
    },
  },
  watch: {
    domain: {
      immediate: true,
      handler() {
        const companyDomain =
          this.defaultLogo === "company"
            ? this.domain
            : this.domain?.split("@")[1]
        this.src = `${this.$config.public.clearbitLogoApi}/${companyDomain}`
      },
    },
  },
  methods: {
    getLogoOnError() {
      this.isErrorIcon = true
      this.src = DEFAULT_LOGO
    },
  },
}
