
import countries from "@evercam/shared/constants/countries"
import { allowKeypress } from "@evercam/shared/utils"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import Autocomplete from "@evercam/shared/components/Autocomplete"
import UserPersonaField from "@/components/persona/UserPersonaField"
import { useCameraStore } from "@/stores/camera"
import { useAccountStore } from "@/stores/account"
import { mapStores } from "pinia"

export default {
  name: "PersonalInformation",
  components: {
    Autocomplete,
    UserPersonaField,
  },
  data() {
    return {
      newEmail: null,
      newFirstName: null,
      newLastName: null,
      newTelephone: null,
      newPersona: null,
      newCountry: null,
      hideForAll: true,
      countries,
      loading: false,
    }
  },
  computed: {
    ...mapStores(useCameraStore, useAccountStore),
    email() {
      return this.accountStore.email
    },
    firstName() {
      return this.accountStore.firstname
    },
    lastName() {
      return this.accountStore.lastname
    },
    country() {
      return this.accountStore.country
    },
    telephone() {
      return this.accountStore.telephone
    },
    persona() {
      return this.accountStore.persona
    },
    ownedCameras() {
      if (this.cameraStore.cameras?.length) {
        return
      }

      return this.cameraStore.cameras.filter((cam) => cam.owned == true)
    },
    unitSystmItems() {
      return Object.entries(this.$t("forms.unit_system.items")).map(
        ([value, name]) => {
          return { name, value }
        }
      )
    },
    localUnitSystem: {
      get() {
        return localStorage?.getItem("localUnitSystem")
      },
      set(unit) {
        this.$units.selectUnits(unit.value)
      },
    },
  },
  created() {
    this.newCountry = {
      name: "",
      value: this.country?.toUpperCase(),
    }
    this.newEmail = this.accountStore.email
    this.newFirstName = this.accountStore.firstname
    this.newLastName = this.accountStore.lastname
    this.newTelephone = this.accountStore.telephone
    this.newPersona = this.accountStore.persona
  },
  methods: {
    async updateUser() {
      let payload = {}
      if (this.newEmail && this.newEmail !== this.email) {
        payload = { ...payload, email: this.newEmail }
      }
      if (this.newFirstName && this.newFirstName !== this.firstName) {
        payload = { ...payload, firstname: this.newFirstName }
      }
      if (this.newLastName && this.newLastName !== this.lastName) {
        payload = { ...payload, lastname: this.newLastName }
      }
      if (this.newTelephone !== this.telephone) {
        payload = { ...payload, telephone: this.newTelephone }
      }
      if (this.newCountry?.value?.toLowerCase() !== this.country) {
        payload = { ...payload, country: this.newCountry?.value?.toLowerCase() }
      }
      if (this.newPersona !== this.persona) {
        payload = { ...payload, persona: this.newPersona }
      }

      try {
        this.loading = true
        const { users } = await EvercamApi.users.update(this.email, payload)

        this.accountStore.updateUser({
          firstname: users[0].firstname,
          lastname: users[0].lastname,
          email: users[0].email,
          telephone: users[0].telephone,
          country: users[0].country,
          persona: users[0].persona,
          isAdmin: this.$permissions.user.is.admin(),
        })

        this.$notifications.success("User modified successfully")
        this.$refs.obs.reset()
      } catch (error) {
        this.$refs.obs.setErrors(error.response.data.message)
        this.$errorTracker?.save(error.response.data.message)
      } finally {
        this.loading = false
      }
    },
    allowKeypress,
  },
}
