
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import { mapStores } from "pinia"
import { useAccountStore } from "@/stores/account"

export default {
  name: "UserPassword",
  data() {
    return {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
      loading: false,
    }
  },
  computed: {
    ...mapStores(useAccountStore),
  },
  methods: {
    changePassword() {
      this.loading = true
      let payload = {
        currentPassword: this.currentPassword,
        newPassword: this.newPassword,
      }
      EvercamApi.users
        .remotePasswordUpdate(this.accountStore.email, payload)
        .then((response) => {
          this.$notifications.success(response.message)
        })
        .catch((error) => {
          this.$notifications.error({
            text: this.$t("content.account.update_password_failed"),
            error,
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    async sendResetRequest() {
      this.loading = true
      try {
        await EvercamApi.users.resetPassword(this.accountStore.email)
        this.$notifications.success(
          this.$t("content.account.reset_password_notification")
        )
      } catch (error) {
        this.$notifications.error({
          text: this.$t("content.auth.send_reset_password_failed"),
          error,
        })
      } finally {
        this.loading = false
      }
    },
  },
}
