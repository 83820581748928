
import PersonalInformation from "@/components/users/PersonalInformation"
import UserPassword from "@/components/users/UserPassword"
import Sessions from "@/components/users/Sessions"
import DeleteAccount from "@/components/users/DeleteAccount"
import LogoDisplayer from "@evercam/shared/components/LogoDisplayer"
import { mapStores } from "pinia"
import { useAccountStore } from "@/stores/account"
import {
  AnalyticsEvent,
  AnalyticsEventPageId,
} from "@evercam/shared/types/analytics"

export default {
  name: "Account",
  meta: {
    pageId: AnalyticsEventPageId.Account,
  },
  components: {
    PersonalInformation,
    UserPassword,
    Sessions,
    DeleteAccount,
    LogoDisplayer,
  },
  head() {
    return {
      title: "Evercam | User Settings",
      meta: [
        { charset: "utf-8" },

        {
          hid: "description",
          name: "description",
          content: "TIME-LAPSE & PROJECT MANAGEMENT CAMERAS",
        },
      ],
    }
  },
  computed: {
    ...mapStores(useAccountStore),
  },
  mounted() {
    this.$analytics.saveEvent(AnalyticsEvent.PageView)
  },
}
