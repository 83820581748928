
import { debounce, getQueryParams } from "@evercam/shared/utils"

export default {
  name: "SessionsSearchFilters",
  data() {
    return {
      filters: {
        browser: "",
        os: "",
        ipAddress: "",
      },
    }
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.fireFilter(this)
      },
    },
  },
  mounted() {
    this.filters = { ...this.filters, ...getQueryParams() }
  },
  methods: {
    fireFilter: debounce((self) => {
      self.$emit("search-filters", self.filters)
    }, 500),
  },
}
