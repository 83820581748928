
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import { useCameraStore } from "@/stores/camera"
import { mapStores } from "pinia"
import { useAccountStore } from "@/stores/account"

export default {
  name: "DeleteAccount",
  data() {
    return {
      loading: false,
      dialog: false,
      emailConfirmation: null,
    }
  },
  computed: {
    ...mapStores(useCameraStore, useAccountStore),
    ownedCameras() {
      if (!this.cameraStore.cameras?.length) {
        return null
      }

      return this.cameraStore.cameras.filter((cam) => cam.owned == true)
    },
  },
  methods: {
    goToTrasnferModal(camera) {
      this.$router.push(`/v2/projects/${camera.project.id}/${camera.id}/shares`)
    },
    deleteAccount() {
      this.loading = true
      EvercamApi.users
        .delete(this.accountStore.email, {
          verifEmail: this.emailConfirmation,
        })
        .then(() => {
          this.accountStore.logout({ redirect: false })
          this.$router.push("/good-bye")
        })
        .catch((error) => {
          this.$notifications.error({
            text: this.$t("content.account.delete_failed"),
            error,
          })
          this.loading = false
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
